import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, Link, useParams, useNavigate } from "react-router-dom";
import LeftSideBar from "../components/LeftSideBar";
import baseUrl from "../config/baseurl";

const Details = ({
  showSubMenu,
  isLogin,
  setIsLogin,
  setShowSubMenu,
  isPending,
}) => {
  const { pathname } = useLocation("");
  const { id } = useParams();
  const [data, setData] = useState({});
  const [completed, setCompleted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(`${baseUrl}/order/details?id=${id}`);
        setData(data);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(data?.orderDetails?.other);

  const CompleteOrder = async (flag) => {
    var rep = window.confirm("Are you sure want to mark the order completed !");
    console.log(rep);
    if (flag && rep) {
      try {
        const { data } = await axios.patch(`${baseUrl}/order/updateOrder`, {
          id: id,
        });
        console.log(data);
        navigate("/pendingorders");
      } catch (err) {
        console.log(err);
      }
    }
  };
  console.log(data.other);

  return (
    <div className="min-h-screen flex ">
      <div className="w-[40%] min-w-[200px] max-w-[270px]  min-h-screen bg-[#F8F8F8]">
        <LeftSideBar
          showSubMenu={showSubMenu}
          setShowSubMenu={setShowSubMenu}
        />
      </div>

      <div className="w-[60%]">
        <h1 className="text-3xl font-bold mt-12 ml-10">Order Details</h1>
        <div className="m-10 flex flex-col gap-y-2">
          <p className="mt-2 text-2xl font-semibold">Id: {id}</p>
          {data?.orderDetails?.imageUrls.map((imgs) => (
            <div className="">
              <img src={imgs} alt="imgs" className="w-[50%]" />
            </div>
          ))}

          <p className="">
            <strong>Category:</strong> {data?.orderDetails?.category}
          </p>

          <p className="">
            <strong>Name:</strong> {data?.user?.firstname}{" "}
            {data?.user?.lastname}
          </p>

          <p className="">
            <strong>Phone No:</strong> {data?.user?.contact}
          </p>

          <p className="">
            <strong>Email:</strong> {data?.user?.email}
          </p>

          <p className="">
            <strong>Wide:</strong> {data?.orderDetails?.wide}
          </p>

          <p className="">
            <strong>Long:</strong> {data?.orderDetails?.long}
          </p>

          <p className="">
            <strong>Quantity:</strong> {data?.orderDetails?.quantity}
          </p>
          {data?.orderDetails?.category === "Patches" ? (
            <>
              <p className="">
                <strong>Embroidary:</strong>{" "}
                {data?.orderDetails?.embroidary ? "With Embroidary" : ""}
              </p>
              <p className="">
                <strong>Backing:</strong>{" "}
                {data?.orderDetails?.backing ? "Iron on Backing" : "No Backing"}
              </p>
              <p className="">
                <strong>Border Edge</strong>{" "}
                {data?.orderDetails?.borderEdge === 1 && "Single Felt"}
                {data?.orderDetails?.borderEdge === 2 && "Double Felt"}
                {data?.orderDetails?.borderEdge === 3 && "Tripple Felt"}
              </p>
            </>
          ) : (
            data?.orderDetails?.other.map((el, i) => (
              <p className="font-bold" key={i}>
                {el}
              </p>
            ))
          )}

          <p className="">
            <strong>Price</strong> {data?.orderDetails?.totalPrice}
          </p>

          <p className="">
            <strong>Completed</strong>{" "}
            {data?.orderDetails?.completed ? "True" : "False"}
          </p>
          {isPending && (
            <div className=" mt-10 flex items-center gap-x-2 ">
              <input
                className="cursor-pointer"
                checked={completed}
                type="checkbox"
                onChange={(e) => {
                  setCompleted(e.target.checked);
                  CompleteOrder(e.target.checked);
                }}
              />
              <label className="font-bold text-xl"> Mark As Completed</label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Details;
