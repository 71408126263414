import React from "react";
import { RiEditFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { deletePatch } from "../../api/usePatches";

const Patches = ({ data, setToggle, setPatchData, setDeleteToggle }) => {
  return (
    <div className="grid w-full grid-cols-3 gap-5 mt-10">
      {data.map((el) => (
        <div
          key={el._id}
          className="z-10 w-full p-2 py-6 bg-white shadow-2xl rounded-2xl"
        >
          <img
            src={el.images}
            alt={el.name}
            className="object-contain object-center w-full h-[130px] bg-[#f4f4f4] rounded"
          />
          <div className="flex flex-col gap-4 mt-4">
            <p className="text-lg">
              <span className="font-bold">Name:</span> {el.name}
            </p>
            <p className="text-lg">
              <span className="font-bold">Category:</span>{" "}
              {el.patchSubCategoryId.category}
            </p>
            <p className="text-lg">
              <span className="font-bold">Sub Category:</span>{" "}
              {el.patchSubCategoryId.name}
            </p>
          </div>
          <div className="flex justify-between mt-4">
            <button className="bg-[#3b82f6] p-2 rounded text-white">
              <RiEditFill
                onClick={() => {
                  setPatchData(el);
                  setToggle(true);
                }}
              />
            </button>
            <button
              className="p-2 text-white bg-red-500 rounded bg-red"
              onClick={async () => {
                await deletePatch(el._id);
                setDeleteToggle((prev) => !prev);
              }}
            >
              <MdDelete />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Patches;
