import React from "react";
import {
  editPatchSubCategory,
  getFilterPatchSubCategory,
} from "../../api/usePatches";
import { useParams } from "react-router-dom";
import { convertText } from "../../utils/tools";
import { FiEdit2 } from "react-icons/fi";
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";

const SubCategory = ({
  setToggle,
  setEditData,
  patchesSubCategory,
  setPatchesSubCategory,
  isUpdating,
  setIsUpdating,
}) => {
  const { id } = useParams();
  React.useEffect(() => {
    const f = async () => {
      const res = await getFilterPatchSubCategory(convertText(id));
      if (res.status === 201) {
        setPatchesSubCategory(res.data.patch);
      }
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isUpdating]);

  const handelSubmitNext = async (currentId, nextId, index) => {
    await editPatchSubCategory(currentId, { index: index + 1 });
    await editPatchSubCategory(nextId, { index: index });
    setIsUpdating((prev) => !prev);
  };
  const handelSubmitPrevious = async (currentId, prevId, index) => {
    await editPatchSubCategory(currentId, { index: index - 1 });
    await editPatchSubCategory(prevId, { index: index });
    setIsUpdating((prev) => !prev);
  };

  return (
    <div className="grid w-full grid-cols-5 gap-8 mt-8 ">
      {patchesSubCategory
        ?.sort((a, b) => a.index - b.index)
        .map((el, i) => (
          <div
            key={el._id}
            className="flex flex-col items-center justify-center w-full bg-white rounded-2xl h-[150px] z-10 shadow-2xl mt-8 border-2 relative p-2"
          >
            <p className="text-xl font-bold text-center">{el.name}</p>
            <div className="absolute flex justify-between w-full px-2 bottom-2">
              <div className="flex items-center gap-2 select-none">
                <FaCaretLeft
                  className={` cursor-pointer text-blue-500 w-7 h-7 ${
                    0 === i && "text-blue-500/40 cursor-not-allowed"
                  }`}
                  onClick={() => {
                    if (0 !== i) {
                      handelSubmitPrevious(
                        el._id,
                        patchesSubCategory[i - 1]._id,
                        i
                      );
                    }
                  }}
                />
                <FaCaretRight
                  className={` cursor-pointer text-blue-500 w-7 h-7 ${
                    patchesSubCategory.length - 1 === i &&
                    "text-blue-500/40 cursor-not-allowed"
                  }`}
                  onClick={() => {
                    if (patchesSubCategory.length - 1 !== i) {
                      handelSubmitNext(
                        el._id,
                        patchesSubCategory[i + 1]._id,
                        i
                      );
                    }
                  }}
                />
              </div>
              <FiEdit2
                className="cursor-pointer "
                onClick={(e) => {
                  e.preventDefault();
                  setEditData(el);
                  setToggle(true);
                }}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default SubCategory;
