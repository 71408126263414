import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import { useEffect, useState } from "react";
import Dashboard from "./pages/Dashboard";
import Feedback from "./pages/Feedback";
import User from "./pages/User";
import CompletedOrders from "./pages/CompletedOrders";
import PendingOrders from "./pages/PendingOrders";
import Details from "./pages/Details";
import UploadChenillePatches from "./pages/UploadChenillePatches";
import Category from "./pages/Category";
import PatchesPage from "./pages/PatchesPage";
import PatchPage from "./pages/PatchPage";

function App() {
  const [isLogin, setIsLogin] = useState(
    localStorage.getItem("isLogin") ? true : false
  );

  const [showSubMenu, setShowSubMenu] = useState(false);

  useEffect(() => {
    localStorage.setItem("isLogin", isLogin ? "true" : "");
  }, [isLogin]);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Login
              isLogin={isLogin}
              setIsLogin={setIsLogin}
              showSubMenu={showSubMenu}
              setShowSubMenu={setShowSubMenu}
            />
          }
        />

        {isLogin && (
          <>
            <Route
              path="/dashboard"
              element={
                <Dashboard
                  isLogin={isLogin}
                  setIsLogin={setIsLogin}
                  showSubMenu={showSubMenu}
                  setShowSubMenu={setShowSubMenu}
                />
              }
            />

            <Route
              path="/feedback"
              element={
                <Feedback
                  isLogin={isLogin}
                  setIsLogin={setIsLogin}
                  showSubMenu={showSubMenu}
                  setShowSubMenu={setShowSubMenu}
                />
              }
            />

            <Route
              path="/pendingorders"
              element={
                <PendingOrders
                  isLogin={isLogin}
                  setIsLogin={setIsLogin}
                  showSubMenu={showSubMenu}
                  setShowSubMenu={setShowSubMenu}
                />
              }
            />

            <Route
              path="/pendingorders/:id"
              element={
                <Details
                  isPending
                  isLogin={isLogin}
                  setIsLogin={setIsLogin}
                  showSubMenu={showSubMenu}
                  setShowSubMenu={setShowSubMenu}
                />
              }
            />

            <Route
              path="/completedorders"
              element={
                <CompletedOrders
                  isLogin={isLogin}
                  setIsLogin={setIsLogin}
                  showSubMenu={showSubMenu}
                  setShowSubMenu={setShowSubMenu}
                />
              }
            />

            <Route
              path="/completedorders/:id"
              element={
                <Details
                  isLogin={isLogin}
                  setIsLogin={setIsLogin}
                  showSubMenu={showSubMenu}
                  setShowSubMenu={setShowSubMenu}
                />
              }
            />

            <Route
              path="/user"
              element={
                <User
                  isLogin={isLogin}
                  setIsLogin={setIsLogin}
                  showSubMenu={showSubMenu}
                  setShowSubMenu={setShowSubMenu}
                />
              }
            />

            <Route
              path="/upload"
              element={
                <UploadChenillePatches
                  isLogin={isLogin}
                  setIsLogin={setIsLogin}
                  showSubMenu={showSubMenu}
                  setShowSubMenu={setShowSubMenu}
                />
              }
            />
            <Route
              path="/patches/:id"
              element={
                <PatchesPage
                  isLogin={isLogin}
                  setIsLogin={setIsLogin}
                  showSubMenu={showSubMenu}
                  setShowSubMenu={setShowSubMenu}
                />
              }
            />
            <Route
              path="/patch/:id"
              element={
                <PatchPage
                  isLogin={isLogin}
                  setIsLogin={setIsLogin}
                  showSubMenu={showSubMenu}
                  setShowSubMenu={setShowSubMenu}
                />
              }
            />

            <Route
              path="/upload/:id"
              element={
                <Category
                  isLogin={isLogin}
                  setIsLogin={setIsLogin}
                  showSubMenu={showSubMenu}
                  setShowSubMenu={setShowSubMenu}
                />
              }
            />
          </>
        )}
      </Routes>
    </>
  );
}

export default App;
