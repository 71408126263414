import axios from "axios";
import baseUrl from "../config/baseurl";

export const uploadMedia = async (formData) => {
    const res = await axios({
      url: `${baseUrl}/media/upload`,
      method: "POST",
      data: formData,
    });
  
    return res;
  };