import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import { BsMenuButtonWide } from "react-icons/bs";
import { IoMdCart } from "react-icons/io";
import { TbSteeringWheel } from "react-icons/tb";
import LeftSideBar from "../components/LeftSideBar";
import axios from "axios";
import baseUrl from "../config/baseurl";
import Cookies from "js-cookie";

const Dashboard = ({ showSubMenu, isLogin, setIsLogin, setShowSubMenu }) => {
  const nav = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(`${baseUrl}/getCounts`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        });
        setData(data);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, []);

  return (
    <div className="flex min-h-screen">
      <div className="w-[40%] min-w-[200px] max-w-[270px]  min-h-screen bg-[#F8F8F8]">
        <LeftSideBar
          showSubMenu={showSubMenu}
          setShowSubMenu={setShowSubMenu}
        />
      </div>

      <div className="min-w-[60%] w-full flex flex-row justify-between  px-5">
        <div className=" flex flex-col    w-[23%]">
          <div className="flex items-center justify-center text-white text-3xl mt-20  px-5 py-5 bg-[#337AB7] rounded-[12px] rounded-b-[0px] h-[100px] shadow   border-[#337AB7] border ">
            <FaUserAlt size={70} color="white" />
            <div className="ml-4 text-2xl">
              <p>{data?.userCount}</p>
              <span className="">Users List</span>
            </div>
          </div>

          <div
            onClick={() => nav("/user")}
            className="text-[#337AB7] p-3  border-[#337AB7] border w-full rounded-b-[20px] cursor-pointer"
          >
            View Details
          </div>
        </div>

        <div className=" flex flex-col    w-[23%]">
          <div className="flex items-center justify-center text-white text-3xl mt-20  px-5 py-5 bg-[#5CB85C] rounded-[12px] rounded-b-[0px] h-[100px] shadow   border-[#5CB85C] border w-full">
            <BsMenuButtonWide size={70} color="white" />

            <div className="ml-4 text-2xl">
              <p>{data?.completedOrder}</p>
              <span className="">Compeleted Orders</span>
            </div>
          </div>

          <div
            onClick={() => nav("/")}
            className="text-[#5CB85C] p-3    border-[#5CB85C] border w-full rounded-b-[20px] cursor-pointer"
          >
            View Details
          </div>
        </div>

        <div className=" flex flex-col    w-[23%]">
          <div className="flex items-center justify-center text-white text-3xl mt-20  px-5 py-5 bg-[#F0AD4E] rounded-[12px] rounded-b-[0px] h-[100px] shadow w-full  border-[#F0AD4E] border ">
            <IoMdCart size={70} color="white" />
            <div className="ml-4 text-2xl">
              <p>{data?.pendingOrder}</p>
              <span className="">Pending Orders</span>
            </div>
          </div>

          <div className="text-[#F0AD4E] p-3    border-[#F0AD4E] border w-full rounded-b-[20px] cursor-pointer">
            View Details
          </div>
        </div>

        <div className=" flex flex-col    w-[23%]">
          <div className="flex items-center justify-center text-white text-3xl mt-20  px-5 py-5 bg-[#D9534F] rounded-[12px] rounded-b-[0px] h-[100px] shadow w-full  border-[#D9534F] border ">
            <TbSteeringWheel size={70} color="white" />
            <div className="ml-4 text-2xl">
              <p>{data?.feedback}</p>
              <span className="">Feedback</span>
            </div>
          </div>

          <div className="text-[#D9534F] p-3   border-[#D9534F] border  rounded-b-[20px] cursor-pointer w-full">
            View Details
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
