import React from "react";
import LeftSideBar from "../components/LeftSideBar";
import { useParams } from "react-router-dom";
import { getPatchBySubCategoryId } from "../api/usePatches";
import CreatePatch from "../components/Patch/CreatePatch";
import Patches from "../components/Patch/Patches";

const PatchPage = ({ isLogin, setIsLogin, showSubMenu, setShowSubMenu }) => {
  const [toggle, setToggle] = React.useState(false);
  const { id } = useParams();
  const [data, setData] = React.useState([]);
  const [subCategory, setSubCategory] = React.useState();
  const [patchData, setPatchData] = React.useState(null);
  const [deleteToggle, setDeleteToggle] = React.useState(false);

  React.useEffect(() => {
    const f = async () => {
      const res = await getPatchBySubCategoryId(id);
      setData(res.data.patch);
      setSubCategory(res.data.patchSubCategory);
    };
    f();
  }, [id,deleteToggle]);
  console.log(subCategory);
  return (
    <div className="relative flex gap-10 ">
      <div className="w-[40%] min-w-[200px] max-w-[270px]  min-h-screen bg-[#F8F8F8]">
        <LeftSideBar
          showSubMenu={showSubMenu}
          setShowSubMenu={setShowSubMenu}
        />
      </div>
      <div className="flex flex-col w-full">
        <p className="mt-8 text-4xl font-bold">{subCategory?.category}</p>
        <Patches
          data={data}
          setToggle={setToggle}
          setPatchData={setPatchData}
          setDeleteToggle={setDeleteToggle}
        />
      </div>
      <div
        className="fixed flex items-center justify-center w-10 h-10 text-2xl text-white bg-blue-500 rounded-full cursor-pointer fot-bold bottom-8 right-10"
        onClick={() => setToggle(true)}
      >
        <p>+</p>
      </div>
      {toggle && (
        <CreatePatch
          setToggle={setToggle}
          patchData={patchData}
          subCategory={subCategory}
          setPatchData={setPatchData}
        />
      )}
    </div>
  );
};

export default PatchPage;
