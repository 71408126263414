import React from "react";
import { Link, useLocation,useNavigate } from "react-router-dom";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import Cookies from "js-cookie";

const LeftSideBar = ({ showSubMenu, setShowSubMenu }) => {
  const { pathname } = useLocation("");
  const nav = useNavigate();
  const handelLogOut =()=>{
    Cookies.remove("token")
    localStorage.removeItem("isLogin")
    nav('/')
  }

  return (
    <>
      <p className="py-2 pl-4 text-xl font-bold cursor-pointer">
        Periapparel Admin Panel
      </p>
      <Link to="/dashboard">
        <p
          className={`${
            pathname === "/dashboard" ? "bg-blue-400 text-white" : ""
          } font-semibold py-2 pl-4 cursor-pointer hover:font-bold transition-all ease-in-out duration-300`}
        >
          Dashboard
        </p>
      </Link>
      <Link to="/user">
        <p
          className={`${
            pathname === "/user" ? "bg-blue-400 text-white" : ""
          } font-semibold py-2 pl-4 cursor-pointer hover:font-bold transition-all ease-in-out duration-300`}
        >
          Users
        </p>
      </Link>
      <div onClick={() => setShowSubMenu(!showSubMenu)}>
        <p
          className={`${
            pathname === "/order" ? "bg-blue-400 text-white" : ""
          }  font-semibold py-2 pl-4 cursor-pointer hover:font-bold transition-all ease-in-out duration-300 flex items-center `}
        >
          <span>Order</span>
          {!showSubMenu ? (
            <RiArrowDropDownLine className="ml-5" size={30} />
          ) : (
            <RiArrowDropUpLine className="ml-5" size={30} />
          )}
        </p>
      </div>

      {showSubMenu && (
        <div className="">
          <Link to="/pendingorders">
            <p
              className={`pl-4 ${
                pathname === "/pendingorders" ? "bg-blue-400 text-white" : ""
              }  font-semibold py-2 pl-4 cursor-pointer hover:font-bold transition-all ease-in-out duration-300`}
            >
              Pending Orders
            </p>
          </Link>

          <Link to="/completedorders">
            <p
              className={`pl-4 ${
                pathname === "/completedorders" ? "bg-blue-400 text-white" : ""
              }  font-semibold py-2 pl-4 cursor-pointer hover:font-bold transition-all ease-in-out duration-300`}
            >
              Completed Orders
            </p>
          </Link>
        </div>
      )}

      <Link to="/feedback">
        <p
          className={`${
            pathname === "/feedback" ? "bg-blue-400 text-white" : ""
          } font-semibold py-2 pl-4 cursor-pointer hover:font-bold transition-all ease-in-out duration-300`}
        >
          Feedback
        </p>
      </Link>
      <Link to="/upload">
        <p
          className={`${
            pathname.startsWith("/upload") ? "bg-blue-400 text-white" : ""
          } font-semibold py-2 pl-4 cursor-pointer hover:font-bold transition-all ease-in-out duration-300`}
        >
          <span>Upload Image</span>
        </p>
      </Link>
      <Link to="/patches/chenille">
        <p
          className={`${
            pathname.startsWith("/patches") || pathname.startsWith("/patch")  ? "bg-blue-400 text-white" : ""
          } font-semibold py-2 pl-4 cursor-pointer hover:font-bold transition-all ease-in-out duration-300`}
        >
          <span>Patches</span>
        </p>
      </Link>
      <div className="p-4 cursor-pointer" onClick={()=>handelLogOut()}>
        <p className="font-bold">Log Out</p>
      </div>
    </>
  );
};

export default LeftSideBar;
