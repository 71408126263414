import axios from "axios";
import baseUrl from "../config/baseurl";

export const createPatchSubCategory = async (data) => {
  try {
    const res = await axios.post(`${baseUrl}/patcheSubcategory/create`, data)
    return res
  } catch (err) {
    console.log(err.message)
  }
};

export const editPatchSubCategory = async (id,data) => {
  try {
    const res = await axios.put(`${baseUrl}/patcheSubcategory/update/${id}`, data)
    return res
  } catch (err) {
    console.log(err)
  }
};

export const getFilterPatchSubCategory = async (category) => {
  try {
    const res = await axios.get(`${baseUrl}/patcheSubcategory/filter?category=${category}`)
    return res
  } catch (err) {
    console.log(err.message)
  }
};
export const getPatchBySubCategoryId = async (id) => {
  try {
    const res = await axios.get(`${baseUrl}/patches/all/${id}`)
    return res
  } catch (err) {
    console.log(err.message)
  }
};

export const createPatch = async (data) => {
  try {
    const res = await axios.post(`${baseUrl}/patches/create`, data)
    return res
  } catch (err) {
    console.log(err.message)
  }
};
export const editPatch = async (id , data) => {
  try {
    const res = await axios.put(`${baseUrl}/patches/update/${id}`, data)
    return res
  } catch (err) {
    console.log(err.message)
  }
};
export const deletePatch = async (id) => {
  try {
    const res = await axios.delete(`${baseUrl}/patches/delete/${id}`)
    return res
  } catch (err) {
    console.log(err.message)
  }
};
