import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import baseUrl from "../config/baseurl";
import Cookies from "js-cookie";

const Login = ({ showSubMenu, isLogin, setIsLogin, setShowSubMenu }) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [submiting, setSubmitting] = useState(false);
  const nav = useNavigate();

  const submitHandler = async (e) => {
    setSubmitting(true);
    e.preventDefault();
    try {
      const { data } = await axios.post(`${baseUrl}/admin/auth`, {
        username: userName,
        password: password,
      });
      console.log(data);
      if (data.message === "SUCCESS") {
        setIsLogin(true);
        Cookies.set("token", data.token);
        nav("/dashboard");
      } else {
        setIsLogin(false);
      }
    } catch (err) {
      if (err?.response?.status === 400) {
        alert("Wrong User Name or Password");
        setIsLogin(false);
      }
      console.log(err);
    }
    setSubmitting(false);
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="w-full min-h-[50vh]  ">
        <form
          onSubmit={submitHandler}
          className="flex flex-col items-center justify-center px-16 py-16 pt-10 mx-auto w-fit bs1"
        >
          <h1 className="text-3xl font-bold">Admin Login</h1>
          <input
            type="text"
            placeholder="Enter user name"
            className="min-w-[300px] py-1 px-2 mt-10 border border-black rounded-md outline-none"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
          />

          <input
            type="password"
            placeholder="Enter Password"
            className="min-w-[300px] py-1 px-2 mt-6 border border-black rounded-md outline-none"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <button
            disabled={submiting}
            type="submit"
            className="px-5 py-2 mt-4 text-white bg-blue-500 rounded shadow"
          >
            {submiting ? "Please Wait.." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
