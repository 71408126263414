import React from "react";
import { FaFileImage } from "react-icons/fa";
import { BiLoaderAlt } from "react-icons/bi";
import { uploadMedia } from "../../api/useImageUpload";
import { IoClose } from "react-icons/io5";

const ImageUploader = ({ image, setImage }) => {
  const [loader, setLoader] = React.useState(false);
  const handelMedia = async (event) => {
    setLoader(true);
    const fileObj = event.target.files[0];
    if (!fileObj) {
      return;
    }
    event.target.value = "";

    let fd = new FormData();
    fd.append("medias", fileObj);
    fd.append("path", "Patches");
    const res = await uploadMedia(fd);
    if (res.status === 200) {
      setImage((prev) => [...prev, ...res.data.urls]);
    }
    setLoader(false);
  };

  return (
    <div>
      <div className="flex items-center justify-center w-full cursor-pointer">
        <label className="flex flex-col w-full border-2 border-gray-200 border-dashed rounded-lg cursor-pointer hover:border-gray-300 hover:bg-gray-50 ">
          <div className="flex flex-col items-center justify-center pt-7">
            <FaFileImage className="w-12 h-12 text-deep-purple-500" />
            <p className="py-1 text-sm font-medium tracking-widest text-gray-500 ">
              Select your
              <span className="text-deep-purple-300"> image </span>
              here
            </p>
            <p className="mt-1 text-xs font-medium tracking-widest text-gray-500 ">
              JPG,PNG and SVG files are allowed
            </p>
          </div>
          <input
            type="file"
            className="h-1 mb-3 opacity-0"
            accept="image/png, image/jpeg,image/jpg,image/ai,image/eps,image/psd,image/tif,image/tiff,image/pdf"
            required={false}
            onChange={handelMedia}
            // multiple
          />
        </label>
      </div>
      {image.length !== 0 && (
        <div className="grid grid-cols-3 gap-3">
          {image.map((el, i) => (
            <div
              className="relative flex justify-center bg-[#f4f4f4] rounded mt-3"
              key={i}
            >
              <img
                src={el}
                className="object-contain h-[100px] w-full"
                alt="Patch"
              />
              <IoClose
                className="absolute flex flex-col w-6 h-6 p-1 font-bold text-white bg-blue-500 rounded-full cursor-pointer -right-2 -top-2"
                onClick={() => {
                  setImage(prev=> prev.filter(item=> item !== el))
                }}
              />
            </div>
          ))}
        </div>
      )}
      {loader && (
        <div className="flex flex-col items-center justify-center gap-4 mt-3">
          <BiLoaderAlt className="w-8 h-8 mr-2 animate-spin" />
          <p className="text-xl font-bold">Uploading Image ......</p>
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
