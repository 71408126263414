import React from "react";
import { IoClose } from "react-icons/io5";
import ImageUploader from "../Patches/ImageUploader";
import { createPatch, editPatch } from "../../api/usePatches";
import { useParams } from "react-router-dom";

const CreatePatch = ({ setToggle, patchData, setPatchData, subCategory }) => {
  const nameRef = React.useRef();
  const { id } = useParams();
  const [image, setImage] = React.useState(
    patchData === null ? null : patchData.images
  );
  const handelSubmit = async () => {
    if (patchData === null) {
      await createPatch({
        name: nameRef.current.value,
        patchSubCategoryId: id,
        images: image,
      });
    } else {
      await editPatch(patchData._id, {
        name: nameRef.current.value,
        images: image,
      });
    }
  };

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-black/10 ">
      <form className="flex flex-col w-full max-w-lg gap-8 p-4 bg-white rounded-lg">
        <div className="flex items-center justify-between cursor-pointer">
          <div></div>
          <p className="text-2xl font-bold">
            {patchData == null ? "Create" : "Edit"} {subCategory?.name} Patch
          </p>
          <IoClose
            className="flex flex-col w-8 h-8 p-2 font-bold text-white bg-blue-500 rounded-full"
            onClick={() => {
              setToggle(false);
              setPatchData(null);
            }}
          />
        </div>
        <div className="flex justify-between gap-3">
          <div className="flex flex-col w-full gap-1">
            <label className="text-lg">Description</label>
            <textarea
              rows={4}
              ref={nameRef}
              defaultValue={patchData === null ? undefined : patchData.name}
              className="p-1 border border-black rounded"
              placeholder="Enter Name"
              required
            />
          </div>
        </div>
        <ImageUploader image={image} setImage={setImage} />

        <button
          className="p-2 font-bold text-white bg-blue-500 rounded"
          onClick={() => handelSubmit()}
        >
          {" "}
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreatePatch;
