import React from "react";
import { IoClose } from "react-icons/io5";
import {
  createPatchSubCategory,
  editPatchSubCategory,
} from "../../api/usePatches";
import { useParams } from "react-router-dom";
import { convertText } from "../../utils/tools";
import ImageUploader from "./ImageUploader";
const CreateSubCategory = ({
  setToggle,
  editData,
  setEditData,
  patchesSubCategory,
  setIsUpdating,
}) => {
  const [image, setImage] = React.useState(
    editData === null ? [] : editData.images
  );
  const nameRef = React.useRef();
  const descriptionRef = React.useRef();
  const { id } = useParams();
  const handelSubmit = async () => {
    if (editData === null) {
      await createPatchSubCategory({
        name: nameRef.current.value,
        description: descriptionRef.current.value,
        category: convertText(id),
        images: image,
        index: patchesSubCategory.length,
      });
      setToggle(false);
      setEditData(null);
      setIsUpdating((prev) => !prev);
    } else {
      await editPatchSubCategory(editData._id, {
        name: nameRef.current.value,
        description: descriptionRef.current.value,
        category: convertText(id),
        images: image,
      });
      setToggle(false);
      setEditData(null);
      setIsUpdating((prev) => !prev);
    }
  };
  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-black/10 ">
      <form className="flex flex-col w-full max-w-lg gap-8 p-4 bg-white rounded-lg max-h-[90vh] overflow-y-scroll scrollbar scrollbar-w-1 scrollbar-thumb-blue-400 scrollbar-thumb-rounded-full">
        <div className="flex items-center justify-between cursor-pointer">
          <div></div>
          <p className="text-2xl font-bold">
            {editData === null ? "Create" : "Edit"} Sub Category
          </p>
          <IoClose
            className="flex flex-col w-8 h-8 p-2 font-bold text-white bg-blue-500 rounded-full"
            onClick={() => {
              setToggle(false);
              setEditData(null);
            }}
          />
        </div>
        <div className="flex justify-between gap-3">
          <div className="flex flex-col w-full gap-1">
            <label className="text-lg">Name</label>
            <input
              ref={nameRef}
              defaultValue={editData === null ? undefined : editData.name}
              className="p-1 border border-black rounded"
              placeholder="Enter Sub Category Name"
              required
            />
          </div>
        </div>
        <div className="flex flex-col w-full gap-1">
          <label className="text-lg">Description</label>
          <textarea
            rows={3}
            ref={descriptionRef}
            defaultValue={editData === null ? undefined : editData.description}
            className="p-1 border border-black rounded"
            placeholder="Enter Description"
            required
          />
        </div>
        <ImageUploader image={image} setImage={setImage} />
        <button
          className="p-2 font-bold text-white bg-blue-500 rounded"
          type="button"
          onClick={() => handelSubmit()}
        >
          {" "}
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateSubCategory;
