import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LeftSideBar from "../components/LeftSideBar";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import baseUrl from "../config/baseurl";
import Loader from "../components/Loader";
// import { Container } from '../components/Container';
// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
import { IoMdArrowDropupCircle } from "react-icons/io";
import { MdArrowDropDownCircle } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import Cookies from "js-cookie";
import Dialog from "../components/Category/Dialog";
import { useLocation } from "react-router-dom";
import { convertText } from "../utils/tools";

const Category = ({ showSubMenu, isLogin, setIsLogin, setShowSubMenu }) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cat = searchParams.get("cat");
  const nav = useNavigate();
  const [imageFile, setImageFile] = useState([]);
  const [patchId, setPatchId] = useState();
  const [data, setData] = useState([]);
  const [sign, setSign] = useState(false);
  const [type, setType] = useState("Create");
  const [category, setCategory] = useState(
    localStorage.getItem("category")
      ? localStorage.getItem("category")
      : "chenile patches"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [subCategory, setSubCategory] = useState(
    localStorage.getItem("category") === "jackets" ? "Varsity" : "Hoodies"
  );
  const [description, setDescription] = useState("");

  const jacketTypes = [
    "Varsity",
    "PolySatin",
    "Leather",
    "PolyTwill",
    "CottonTwill",
  ];

  const hoodiesTypes = ["Hoodies", "Sweatshirt"];

  useEffect(() => {
    if (id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sign, category]);

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`${baseUrl}/patch/images`, {
        params: { category: category, title: id },
      });
      setData([...data]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const uploadImageHandler = async () => {
    if (imageFile.length > 5) {
      alert("Can not upload more than 5 images at a time.");
    } else {
      try {
        setIsUploading(true);
        const formData = new FormData();
        imageFile[0] && formData.append("images", imageFile[0]);
        imageFile[1] && formData.append("images", imageFile[1]);
        imageFile[2] && formData.append("images", imageFile[2]);
        imageFile[3] && formData.append("images", imageFile[3]);
        imageFile[4] && formData.append("images", imageFile[4]);
        formData.append("title", id);
        formData.append("category", category);
        (category === "jackets" || category === "hoodies") &&
          subCategory &&
          formData.append("subCategory", subCategory);
        description && formData.append("description", description);
        formData.append("count", data.length);
        // formData.append("index", +index)
        const res = await axios.post(`${baseUrl}/patch/images`, formData);
        if (res?.data?.message === "OK") {
          setIsUploading(false);
          setSign(!sign);
          setShowPopUp(false);
        }
        setIsUploading(false);
      } catch (err) {
        setIsUploading(false);
      }
    }
  };

  const handelUpdate = async () => {
    if (imageFile.length > 5) {
      alert("Can not upload more than 5 images at a time.");
    } else {
      try {
        setIsUploading(true);
        const formData = new FormData();
        imageFile[0] && formData.append("images", imageFile[0]);
        imageFile[1] && formData.append("images", imageFile[1]);
        imageFile[2] && formData.append("images", imageFile[2]);
        imageFile[3] && formData.append("images", imageFile[3]);
        imageFile[4] && formData.append("images", imageFile[4]);
        formData.append("title", id);
        formData.append("patchId", "64ae092232f5cd8608e31fb0");
        formData.append("category", category);
        (category === "jackets" || category === "hoodies") &&
          subCategory &&
          formData.append("subCategory", subCategory);
        description && formData.append("description", description);
        formData.append("count", data.length);
        // formData.append("index", +index)
        const res = await axios.put(
          `${baseUrl}/patch/images/${patchId}`,
          formData
        );
        if (res?.data?.message === "OK") {
          setIsUploading(false);
          setSign(!sign);
          setShowPopUp(false);
        }
        setIsUploading(false);
      } catch (err) {
        console.log(err.response.data.message);
        setIsUploading(false);
      }
    }
  };

  const deleteHandler = async (_id) => {
    console.log(_id);
    let flag = window.confirm("Are You Sure to delete this image");
    if (flag) {
      try {
        const { data } = await axios.delete(`${baseUrl}/patch/images/delete`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
          data: {
            id: _id,
          },
        });
        if (data.message === "OK") {
          setSign(!sign);
        }
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const updateIndexHandler = async (id, newIndex) => {
    // if(DragIndex){
    try {
      const res = await axios.patch(
        `${baseUrl}/patch/images/index`,
        {
          id,
          newIndex,
        },
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      console.log(res.data);
      setSign(!sign);
      // getData()
    } catch (err) {
      console.log(err);
    }
    // }
  };

  return (
    <>
      <div className="flex min-h-screen">
        <div className="w-[40%] min-w-[200px] max-w-[270px]  min-h-screen bg-[#F8F8F8]">
          <LeftSideBar
            showSubMenu={showSubMenu}
            setShowSubMenu={setShowSubMenu}
          />
        </div>

        <div className="w-[60%]">
          <div className="  py-2 px-10 mt-8">
            <p className="font-bold text-xl flex items-center gap-2 cursor-pointer">
              {" "}
              <span className="hover:underline" onClick={()=> nav('/upload')}>{convertText(cat)}</span>{" "}
              <FaChevronRight />
              <span className="hover:underline">{id}</span>
            </p>
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center min-h-screen">
              <Loader />
            </div>
          ) : (
            <table className="mt-4 ml-10 mytable">
              <tr>
                <th className="w-[400px]">Image</th>
                <th className="w-[100px]">Index</th>
                <th className="w-[200px]">Action</th>
              </tr>

              {data?.map((item, index) => (
                <tr className="w-full " key={index}>
                  <td className="w-[400px] relative ">
                    {
                      <img
                        src={item?.url}
                        alt="index"
                        className="  max-w-[200px] max-h-[200px] object-contain"
                      />
                    }
                  </td>
                  <td className="text-center w-[100px]">{item.index}</td>
                  <td className=" w-[200px]">
                    <div className="flex gap-2">
                      <button
                        onClick={() => deleteHandler(item._id)}
                        className="bg-red-500 text-white px-5 py-1 rounded-[10px]"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => {
                          setCategory(item.category);
                          setSubCategory(item.subCategory);
                          setDescription(item.description);
                          setShowPopUp(true);
                          setType("Edit");
                          setPatchId(item._id);
                        }}
                        className="bg-blue-500 text-white px-5 py-1 rounded-[10px]"
                      >
                        Edit
                      </button>
                    </div>
                    {index !== 0 && (
                      <IoMdArrowDropupCircle
                        color="blue"
                        className="inline-block ml-2"
                        cursor={"pointer"}
                        size={30}
                        onClick={() => updateIndexHandler(item._id, index)}
                      />
                    )}
                    {index !== data.length - 1 && (
                      <MdArrowDropDownCircle
                        color="blue"
                        cursor="pointer"
                        className="inline-block ml-2"
                        size={30}
                        onClick={() => updateIndexHandler(item._id, index + 2)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </table>
          )}
          <div
            onClick={() => {
              setShowPopUp(true);
            }}
            className="fixed bottom-10 right-10 cursor-pointer  w-[40px] h-[40px] text-white font-semibold text-3xl rounded-full bg-blue-600 flex items-center justify-center"
          >
            +
          </div>
        </div>

        {showPopUp && (
          <Dialog
            category={category}
            isUploading={isUploading}
            uploadImageHandler={uploadImageHandler}
            setShowPopUp={setShowPopUp}
            setImageFile={setImageFile}
            subCategory={subCategory}
            setSubCategory={setSubCategory}
            jacketTypes={jacketTypes}
            hoodiesTypes={hoodiesTypes}
            setDescription={setDescription}
            description={description}
            handelUpdate={handelUpdate}
            data={null}
            type={type}
          />
        )}
      </div>
    </>
  );
};

export default Category;
