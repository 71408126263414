import React from "react";
import LeftSideBar from "../components/LeftSideBar";
import CreateSubCategory from "../components/Patches/CreateSubCategory";
import { useParams, Link } from "react-router-dom";
import SubCategory from "../components/Patches/SubCategory";

const PatchesPage = ({ isLogin, setIsLogin, showSubMenu, setShowSubMenu }) => {
  const [patchesSubCategory, setPatchesSubCategory] = React.useState([]);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const { id } = useParams();

  return (
    <div className="relative flex gap-10 ">
      <div className="w-[40%] min-w-[200px] max-w-[270px]  min-h-screen bg-[#F8F8F8]">
        <LeftSideBar
          showSubMenu={showSubMenu}
          setShowSubMenu={setShowSubMenu}
        />
      </div>
      <div className="flex flex-col w-full mt-10">
        <h3 className="text-4xl font-bold">Patches</h3>
        <div className="flex bg-[#6b7280] rounded-lg  gap-10 mt-6 text-white select-none max-w-[33.45rem]">
          <Link
            to="/patches/chenille"
            className={`${
              id === "chenille" && "bg-blue-500 "
            } p-1 rounded-l-lg px-4`}
          >
            Chenille
          </Link>
          <Link
            to="/patches/embroidery"
            className={`${id === "embroidery" && "bg-blue-500 "} p-1  px-4`}
          >
            Embroidery
          </Link>
          <Link
            to="/patches/woven"
            className={`${id === "woven" && "bg-blue-500"} p-1  px-4`}
          >
            Woven
          </Link>
          <Link
            to="/patches/vegan-leather"
            className={`${
              id === "vegan-leather" && "bg-blue-500 "
            } p-1 rounded-r-lg px-4`}
          >
            Vegan Leather
          </Link>
        </div>
        <SubCategory
          setToggle={setToggle}
          setEditData={setEditData}
          patchesSubCategory={patchesSubCategory}
          setPatchesSubCategory={setPatchesSubCategory}
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
        />
      </div>

      <div
        className="fixed flex items-center justify-center w-10 h-10 text-2xl text-white bg-blue-500 rounded-full cursor-pointer fot-bold bottom-8 right-10"
        onClick={() => setToggle(true)}
      >
        <p>+</p>
      </div>
      {toggle && (
        <CreateSubCategory
          setToggle={setToggle}
          editData={editData}
          patchesSubCategory={patchesSubCategory}
          setEditData={setEditData}
          setIsUpdating={setIsUpdating}
        />
      )}
    </div>
  );
};

export default PatchesPage;
