import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useLocation, Link, useNavigate } from "react-router-dom";
import LeftSideBar from "../components/LeftSideBar";
import Loader from "../components/Loader";
import Cookies from "js-cookie";
import baseurl from "../config/baseurl";

const PendingOrders = ({
  showSubMenu,
  isLogin,
  setIsLogin,
  setShowSubMenu,
}) => {
  const { pathname } = useLocation("");
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [searchItems, setSearchItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.get(`${baseurl}/order/pendingOrders`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        });
        console.log(data);
        setData(data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  return (
    <div className="min-h-screen flex ">
      <div className="w-[40%] min-w-[200px] max-w-[270px]  min-h-screen bg-[#F8F8F8]">
        <LeftSideBar
          showSubMenu={showSubMenu}
          setShowSubMenu={setShowSubMenu}
        />
      </div>

      <div className="w-[80%]">
        {isLoading ? (
          <div className="flex min-h-screen items-center justify-center">
            <Loader />
          </div>
        ) : (
          <div className="mt-20 ml-12 mr-12 ">
            <div className="flex justify-end w-full">
              <div className=" flex items-center border w-full border-gray-600  overflow-hidden justify-end max-w-[400px] rounded-md">
                <input
                  type="text"
                  placeholder="Search"
                  className="flex-1 p-2 outline-none "
                  onChange={(e) => {
                    setSearch(e.target.value);
                    const searchedArr = data.filter((item) => {
                      if (
                        item._id.startsWith(e.target.value) ||
                        item.updatedAt.startsWith(e.target.value) ||
                        item.category.startsWith(e.target.value) ||
                        item.quantity.toString().startsWith(e.target.value) ||
                        item.totalPrice.toString().startsWith(e.target.value)
                      ) {
                        return item;
                      }
                    });
                    setSearchItems([...searchedArr]);
                    console.log(searchedArr);
                  }}
                />
                <span className="flex-0 px-2 pl-0">
                  <BsSearch size={20} cursor="pointer" />
                </span>
              </div>
            </div>

            <table className="mt-4">
              <tr>
                <th>Order Id</th>
                <th>Date</th>
                <th>Category</th>
                <th>Quantity</th>
                <th>Total Price</th>
              </tr>
              {search ? (
                <>
                  {searchItems.length === 0 ? (
                    <tr className="text-center">
                      <td colSpan={"5"} className="text-center">
                        No data present in the table
                      </td>
                    </tr>
                  ) : (
                    searchItems?.map((user, index) => (
                      <tr
                        className="cursor-pointer"
                        onClick={() => nav(user._id)}
                      >
                        <td>{user?._id}</td>
                        <td>{user?.updatedAt}</td>
                        <td>{user?.category}</td>
                        <td>{user?.quantity}</td>
                        <td>{user?.totalPrice}</td>
                      </tr>
                    ))
                  )}
                </>
              ) : (
                <>
                  {data.length === 0 ? (
                    <tr className="text-center">
                      <td colSpan={"5"} className="text-center">
                        No data present in the table
                      </td>
                    </tr>
                  ) : (
                    data?.map((user, index) => (
                      <tr
                        className="cursor-pointer"
                        onClick={() => nav(user._id)}
                      >
                        <td>{user?._id}</td>
                        <td>{user?.updatedAt}</td>
                        <td>{user?.category}</td>
                        <td>{user?.quantity}</td>
                        <td>{user?.totalPrice}</td>
                      </tr>
                    ))
                  )}
                </>
              )}
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default PendingOrders;
