import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useParams } from "react-router-dom";

const Dialog = ({
  isUploading,
  uploadImageHandler,
  setShowPopUp,
  setImageFile,
  category,
  setSubCategory,
  jacketTypes,
  hoodiesTypes,
  setDescription,
  description,
  data,
  handelUpdate,
  type,
  subCategory
}) => {
  const { id } = useParams();
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[#00000056]">
      <div className="w-[50%] min-w-[500px] min-h-[340px] bg-white bs1 rounded-3xl py-5">
        <div className="flex justify-end px-5 pt-5">
          <AiOutlineClose
            onClick={() => setShowPopUp(false)}
            cursor="pointer"
          />
        </div>
        <h1 className="mt-3 text-2xl font-bold text-center">Upload Image</h1>

        <div className="flex justify-between w-full px-5 mt-10 gap-x-4">
          <div className="w-1/2">
            <label className="font-semibold">Category</label>
            <input
              value={id}
              type="text"
              placeholder="Category"
              className="w-full border border-[#3c3d41b4]  px-2 py-1 rounded-md ml-2 outline-none"
            />
          </div>
          {(category === "jackets" || category === "hoodies") && (
            <div className="w-1/2">
              <label className="font-semibold">Sub Category</label>
              <select
                className="w-full border border-[#3c3d41a9]  px-2 py-1 rounded-md ml-2 outline-none"
                value={subCategory}
                onChange={(e) => setSubCategory(e.target.value)}
              >
                {category === "jackets" && (
                  <>
                    {jacketTypes.map((jac) => (
                      <option value={jac} key={jac}>
                        {jac}
                      </option>
                    ))}
                  </>
                )}
                {category === "hoodies" && (
                  <>
                    {hoodiesTypes.map((hod) => (
                      <option value={hod} key={hod}>
                        {hod}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>
          )}
        </div>
        {(category === "jackets" || category === "hoodies") && (
          <div className="px-5 mt-5">
            <label className="font-semibold">Description</label>
            <textarea
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              placeholder="Description"
              className="w-full border border-[#3c3d41a9] h-[100px]  px-2 py-1 rounded-md ml-2 outline-none"
            ></textarea>
          </div>
        )}

        <input
          onChange={(e) => setImageFile([...e.target.files])}
          type="file"
          className="block w-1/2 mx-auto mt-10 cursor-pointer "
          multiple="multiple"
        />

        <div className="flex justify-end mt-3 mr-5">
          <button
            disabled={isUploading}
            onClick={() => {
              if (type === "Edit") {
                handelUpdate()
              } else {
                uploadImageHandler();
              }
            }}
            className="p-2 px-4 text-white bg-blue-500 rounded-md"
          >
            {isUploading ? "Saving Images..." : "Save Image"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
