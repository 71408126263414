import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import LeftSideBar from '../components/LeftSideBar';
import Loader from '../components/Loader';
import baseUrl from '../config/baseurl';

const Feedback = ({ showSubMenu, isLogin, setIsLogin, setShowSubMenu }) => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const { data } = await axios.get(`${baseUrl}/feedback/get`);
                console.log(data);
                setData(data)
                setIsLoading(false)
            } catch (err) {
                console.log(err);
                setIsLoading(false)

            }
        }
        getData();
    }, [])

    return (
        <div className='min-h-screen flex '>
            <div className='w-[40%] min-w-[200px] max-w-[270px]  min-h-screen bg-[#F8F8F8]'>

                <LeftSideBar
                    showSubMenu={showSubMenu}
                    setShowSubMenu={setShowSubMenu}
                />
            </div>

            <div className='w-[60%] '>
                {isLoading?<div className='min-h-screen flex items-center justify-center'><Loader/></div>:
                <div className='mx-10 mt-20'>
                    <table className='mt-4'>
                        <tr>
                            <th>User Name</th>
                            <th>Feedback</th>
                            <th>Location</th>
                            <th>Action</th>
                        </tr>
                        {data.length == 0 ? <tr className='text-center'>
                            <td colSpan={"5"} className="text-center">
                                No data present in the table</td></tr> :
                            data?.map((item, index) => <tr>
                                <td>{item.username}</td>
                                <td>{item.message}</td>
                                <td>{item.location}</td>
                                <td></td>
                            </tr>)
                        }
                    </table>

                </div>}
            </div>
        </div>
    )
}

export default Feedback
