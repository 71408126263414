import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import LeftSideBar from '../components/LeftSideBar';
import { BsSearch } from 'react-icons/bs';
import baseUrl from '../config/baseurl';
import Loader from '../components/Loader';
import Cookies from 'js-cookie';

const User = ({ showSubMenu, isLogin, setIsLogin, setShowSubMenu }) => {
  const { pathname } = useLocation('')
  const [data, setData] = useState({})
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState('')
  const [searchItems, setSearchItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const getAllUsers = async () => {
      try{
        setIsLoading(true);
        const { data } = await axios.get(`${baseUrl}/user/getAll?page=${pageNo}`,{
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        })
        console.log(data);
        setData({ ...data })
        setIsLoading(false)
      }catch(err){
        console.log(err);
        setIsLoading(false)
      }
    

    }

    getAllUsers()
  }, [pageNo])

  
  return (
    <div className='min-h-screen flex max-w-[95vw]'>
      <div className='w-[40%] min-w-[200px] max-w-[270px]  min-h-screen bg-[#F8F8F8]'>
        <LeftSideBar
          showSubMenu={showSubMenu}
          setShowSubMenu={setShowSubMenu}
        />
      </div>
      <div className='w-full flex-1'>
       {isLoading?
       <div className='flex min-h-screen items-center justify-center'>
       <Loader/>
       </div>
       :
        <div className=' mt-20 ml-12 mr-12 '>
          <div className='flex justify-end w-full'>
            <div className=' flex items-center border w-full border-gray-600  overflow-hidden justify-end max-w-[400px] rounded-md'>
              <input
                type="text"
                placeholder='Search'
                className='flex-1 p-2 outline-none '
                onChange={(e) => {
                  setSearch(e.target.value);
                  const searchedArr = data.result.filter((item) => {
                    if (item.firstname.toLowerCase().startsWith((e.target.value).toLowerCase()) ||
                      item.lastname.toLowerCase().startsWith((e.target.value).toLowerCase()) ||
                      item.email.toLowerCase().startsWith((e.target.value).toLowerCase()) ||
                      item.contact.toLowerCase().startsWith((e.target.value).toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  setSearchItems([...searchedArr])
                  console.log(searchedArr)
                }
                }
              />
              <span className='flex-0 px-2 pl-0'>
                <BsSearch
                  size={20}
                  cursor="pointer"
                />
              </span>
            </div>
          </div>
          <table className='mt-4'>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone No</th>
            </tr>
            {search==""?<> 
            {data?.result?.map((user, index) => (<tr>
              <td>{user?.firstname}</td>
              <td>{user?.lastname}</td>
              <td>{user?.email}</td>
              <td>{user?.contact}</td>
            </tr>))}</>
            :<>
            {searchItems?.map((user, index) => (<tr>
              <td>{user?.firstname}</td>
              <td>{user?.lastname}</td>
              <td>{user?.email}</td>
              <td>{user?.contact}</td>
            </tr>))}
            </>}
          </table>
          <div className='flex mt-5 justify-end mb-10'>
            {Array(data?.pageCount).fill(data?.pageCount).map((number, index) => (
              <div
                onClick={() => setPageNo(index + 1)}
                className={`border border-black p-2 cursor-pointer w-[35px] h-[35px] flex items-center justify-center ${pageNo == index + 1 ? "bg-blue-500 text-white" : ""}`}>
                {index + 1}
              </div>))}
          </div>

        </div>
       }
        <div className=''></div>
      </div>
    </div>
  )
}

export default User



