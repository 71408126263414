import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import LeftSideBar from "../components/LeftSideBar";
import baseUrl from "../config/baseurl";
import { AiOutlineClose } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import Loader from "../components/Loader";
import Cookies from "js-cookie";

const UploadChenillePatches = ({
  showSubMenu,
  isLogin,
  setIsLogin,
  setShowSubMenu,
}) => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [oldTitle, setOldTitle] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [flag, setFlag] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState(
    localStorage.getItem("category")
      ? localStorage.getItem("category")
      : "chenile patches"
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(`${baseUrl}/patch/images/titles`, {
          params: { category: category },
        });
        setData([...data]);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };

    getData();
  }, [flag, category]);

  const editHandler = (i) => {
    setOldTitle(i);
    setShowEditPopup(true);
  };

  const changeTitle = async () => {
    try {
      const { data } = await axios.patch(
        `${baseUrl}/patch/images/update`,
        {
          oldTitle,
          newTitle,
          category: category,
        },
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      if (data?.message === "Title Updated") {
        setFlag(!flag);
        setShowEditPopup(false);
      }
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  const createNewHandler = () => {
    nav(`${title}?cat=${category === "home" ? "gallery" : category}`);
  };
  useEffect(() => {
    localStorage.setItem("category", category);
  }, [category]);

  return (
    <div className="flex min-h-screen">
      <div className="w-[40%] min-w-[200px] max-w-[270px]  min-h-screen bg-[#F8F8F8]">
        <LeftSideBar
          showSubMenu={showSubMenu}
          setShowSubMenu={setShowSubMenu}
        />
      </div>

      <div className="w-[80%]">
        <div className="mx-10 my-10">
          <h1 className="text-3xl font-bold">Upload Images</h1>
          <div className="mt-5">
            <button
              onClick={() => setCategory("chenile patches")}
              className={`${
                category === "chenile patches" ? "bg-blue-500 " : "bg-gray-500 "
              } text-white px-5 py-1 rounded-l-[10px]`}
            >
              Chenille Patches
            </button>
            <button
              onClick={() => setCategory("home")}
              className={`${
                category === "home" ? "bg-blue-500 " : "bg-gray-500 "
              } text-white px-5 py-1 `}
            >
              Gallery
            </button>
            <button
              onClick={() => setCategory("jackets")}
              className={`${
                category === "jackets" ? "bg-blue-500 " : "bg-gray-500 "
              } text-white px-5 py-1 `}
            >
              Jackets
            </button>
            <button
              onClick={() => setCategory("hoodies")}
              className={`${
                category === "hoodies" ? "bg-blue-500 " : "bg-gray-500 "
              } text-white px-5 py-1 rounded-r-[10px]`}
            >
              Garments
            </button>
            {/* <button
              onClick={() => setCategory("homePage")}
              className={`${
                category === "homePage" ? "bg-blue-500 " : "bg-gray-500 "
              } text-white px-5 py-1 rounded-r-[10px]`}
            >
              HomePage
            </button> */}
          </div>
          {isLoading ? (
            <div className="min-h-[70vh] flex items-center justify-center ">
              <Loader />
            </div>
          ) : (
            <div className="flex flex-wrap mt-10 gap-x-20 gap-y-10">
              {data?.map((item, index) => (
                <div
                  onClick={() => {
                    nav(
                      `${item}?cat=${
                        category === "home" ? "gallery" : category
                      }`
                    );
                  }}
                  className="relative w-[200px] cursor-pointer h-[150px] rounded-3xl bs1 flex items-center justify-center"
                >
                  {item}

                  <div
                    className="absolute right-4 top-4 hover:bg-slate-300 rounded-full w-[30px] h-[30px] grid place-items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      editHandler(item);
                    }}
                  >
                    <BiEditAlt />
                  </div>
                </div>
              ))}
            </div>
          )}
          <div
            onClick={() => {
              setShowPopUp(true);
            }}
            className="fixed bottom-10 right-10 cursor-pointer  w-[40px] h-[40px] text-white font-semibold text-3xl rounded-full bg-blue-600 flex items-center justify-center"
          >
            +
          </div>
        </div>
      </div>
      {showEditPopup && (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black/10">
          <div className="min-w-[300px] min-h-[250px] rounded-[20px] bs1 bg-white px-5 py-5">
            <AiOutlineClose
              cursor="pointer"
              onClick={() => setShowEditPopup(false)}
              className="block ml-auto"
            />
            <h1 className="text-2xl font-semibold text-center">Change Title</h1>
            <input
              placeholder="Enter New Title"
              type="text"
              className="w-full border border-black py-1 px-4 rounded-[4px] mt-10 outline-none"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
            />

            <button
              onClick={changeTitle}
              className="bg-green-600 text-white rounded-[12px] px-10 py-2 block mx-auto mt-10"
            >
              Save
            </button>
          </div>
        </div>
      )}

      {showPopUp && (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black/10">
          <div className="min-w-[300px] min-h-[250px] rounded-[20px] bs1 bg-white px-5 py-5">
            <AiOutlineClose
              cursor="pointer"
              onClick={() => setShowPopUp(false)}
              className="block ml-auto"
            />
            <h1 className="text-2xl font-semibold text-center">Change Title</h1>
            <input
              placeholder="Enter New Title"
              type="text"
              className="w-full border border-black py-1 px-4 rounded-[4px] mt-10 outline-none"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <button
              onClick={createNewHandler}
              className="bg-green-600 text-white rounded-[12px] px-10 py-2 block mx-auto mt-10"
            >
              Create
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadChenillePatches;
